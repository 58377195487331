// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bF_k4";
export var caseStudyBackground__lineColor = "bF_kW";
export var caseStudyCta__bgColor = "bF_l0";
export var caseStudyHead__imageWrapper = "bF_kT";
export var caseStudyProjectsSection = "bF_k5";
export var caseStudyQuote__bgDark = "bF_mh";
export var caseStudyQuote__bgLight = "bF_k2";
export var caseStudyQuote__bgRing = "bF_k1";
export var caseStudySolution__ring = "bF_kZ";
export var caseStudySolution__ringThree = "bF_mj";
export var caseStudySolution__ringTwo = "bF_k0";
export var caseStudyVideo = "bF_k7";
export var caseStudyVideo__ring = "bF_k8";
export var caseStudy__bgDark = "bF_kS";
export var caseStudy__bgLight = "bF_kR";